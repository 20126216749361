import { makeStyles, withStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

export const BorderLinearProgress = withStyles((theme) => ({
  root: () => ({
    height: 10,
    borderRadius: 0,
    flexGrow: 1,
    boxShadow: 'none'
  }),
  colorPrimary: () => ({
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  }),
  bar: () => ({
    borderRadius: 0,
    backgroundColor: `${theme.typography.astProgressbarBackground}`,
    zIndex: '999'
  })
}))(LinearProgress);

export const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: 'white',
    display: 'flex'
  },
  active: {
    width: '35px',
    height: '35px',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      width: '25px',
      height: '25px'
    }
  },
  circle: {
    width: '35px',
    height: '35px',
    border: '1px solid grey',
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    [theme.breakpoints.down('sm')]: {
      width: '25px',
      height: '25px'
    }
  },
  completed: {
    width: '35px',
    height: '35px',
    color: 'black',
    zIndex: 1,
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      width: '25px',
      height: '25px'
    }
  }
}));

export const myStyles = makeStyles((theme) => ({
  
  buyingSellingClass: () => ({
    textTransform: 'inherit',
    '&:hover': {
      backgroundColor:'inherit'
    }
  }),
  tooltipwrap: () => ({
    whiteSpace: 'normal !important',
    fontSize: '1em !important'
  }),
  root: () => ({
    '& > *': {
      //   margin: theme.spacing(1),
      width: '100%'
    },
    fontFamily: `${theme?.typography?.astFontFamily} !important`,
    flexGrow: 1,
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      padding: '1px !important'
    },
    '& .Mui-disabled': {
      margin: '1em',
      textTransform: 'none'
    }
  }),
  main: () => ({
    '& a': {
      borderBottom: '1px solid'
    }
  }),
  astServiceSelection: () => ({
    '& a': {
      borderBottom: '0px solid'
    }
  }),
  card: () => ({
    margin: '20px !important',
    [theme.breakpoints.down('md')]: {
      margin: '10px !important'
    },
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: '#f3f3f1 !important'
  }),
  wrapperAgent: () => ({
    display: 'block !important'
  }),
  addalignCenter: () => ({
    display: 'flex',
    alignItems: 'center'
  }),
  headerDiv: () => ({
    width: '70%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      marginTop: '25px',
      fontSize: '0.9em'
    }
  }),
  paddingZero: () => ({
    padding: '0px !important',
    '& span': {
      padding: '0px !important'
    }
  }),
  marginZero: () => ({
    [theme.breakpoints.down('md')]: {
      margin: '0px'
    }
  }),
  width30: () => ({
    width: '20px !important',
    [theme.breakpoints.down('sm')]: {
      width: '20px !important'
    }
  }),
  fullWidthButton: () => ({
    float: 'right',
    margin: '0em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      float: 'none',
      margin: '1em 0em 0em'
    }
  }),
  headline: () => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }),
  cardcontext: () => ({
    boxShadow: '0 2px 7px rgb(0 0 0 / 20%) !important',
    display: 'flex',
    justifyContent: 'space-between',
    div: {
      margin: '10px',
      padding: '10px'
    }
  }),
  cardButton:()=>({
    background:"none"
  }),
  thirdElement: () => ({
    display: 'flex !important',
    whiteSpace: 'nowrap !important',
    alignItems: 'center !important',
    [theme.breakpoints.down('md')]: {
      width: '40px !important'
    }
  }),
  boxShadow: () => ({
    boxShadow: 'inset 3px 3px 3px 2px #000303 !important'
  }),
  optText: () => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    // fontFamily: `${theme?.typography?.astFontFamily} !important`,
    color: `${theme?.typography?.bodyTextColor} !important`,
    // fontSize: `${theme?.typography?.astBodyFontSize} !important`,
    fontSize: '1em',
    '& a': {
      color: `${theme?.typography?.astLinkTextColor}`
    },
    '& p': {
      margin: '7px'
    }
  }),
  consentText: () => ({
    fontWeight: 'none !important',
    // fontFamily: `${theme?.typography?.astFontFamily} !important`,
    // fontSize: `${theme?.typography?.astBodyFontSize} !important` || '1em',
    fontSize: '1em',
    '& a': {
      color: `${theme?.typography?.astLinkTextColor}`
    }
  }),
  // customLinkBtn: () => ({
  //   color: `${theme?.typography?.astLinkTextColor}`
  // }),
  prevButton: () => ({
    fontFamily: `${theme?.typography?.astFontFamily} !important`,
    marginRight: '1em',
    // borderRadius: '20px'
    textTransform: 'none'
  }),
  nextButton: () => ({
    fontFamily: `${theme?.typography?.astFontFamily} !important`,
    // marginLeft: '1em',
    textTransform: 'none !important'
  }),
  close: () => ({
    position: 'absolute!important',
    right: 0
  }),
  helperContenet: () => ({
    width: '70%'
  }),
  helperField: () => ({
    textAlign: 'center'
  }),
  helperQuestion: () => ({
    '&:before': {
      content: '""',
      width: '30%',
      height: '1px',
      backgroundColor: '#C9DEF0',
      margin: '50px auto',
      display: 'block'
    }
  }),
  reachRep: () => ({
    margin: '5% 0px 5% 0px !important'
  }),
  helperButton: () => ({
    fontFamily: `${theme?.typography?.astFontFamily} !important`,
    cursor: 'pointer',
    textAlign: 'center',
    whiteSpace: 'nowrap'
  }),
  internalContent: () => ({
    textAlign: 'center',
    // fontFamily: `${theme?.typography?.astFontFamily} !important`,
    fontSize: `${theme?.typography?.astBodyFontSize}em` || '1em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '1.7em !important'
    }
  }),
  internalSubText: () => ({
    textAlign: 'center'
    // fontFamily: `${theme?.typography?.astFontFamily} !important`
  }),
  toggleButton: () => ({
    // fontFamily: `${theme?.typography?.astFontFamily} !important`
  }),
  selected: () => ({
    width: '230px',
    height: '170px',
    border: '3px solid #000000',
    backgroundColor: `${theme.typography.astSelectedIconColor}`
  }),
  toggleSelected: () => ({
    backgroundColor: `${theme.typography.astSelectedIconColor} !important`,
    color: '#000 !important'
  }),
  customPaddingBtm: () => ({
    paddingBottom: '20px'
  }),
  customMarginTop: () => ({
    marginTop: '10px'
  }),
  noMarginPadding: () => ({
    margin: '0px !important',
    padding: '0px !important'
  }),
  amount: () => ({
    '& *': {
      justifyContent: 'center !important'
    },
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    padding: '20px 0px',
    justifyContent: 'center !important',
    margin: '0px'
  }),
  paper: () => ({
    padding: theme.spacing(2),
    textAlign: 'left',
    color: `${theme?.typography?.astBodyTextColor} !important`,
    boxShadow: 'none !important',
    // fontFamily: `${theme?.typography?.astFontFamily} !important`,
    fontSize: `${theme?.typography?.astBodyFontSize}em !important` || '1em'
  }),
  inputField: () => ({
    width: '100%',
    fontFamily: `${theme?.typography?.astFontFamily} !important`,
    '& fieldset': {
      borderColor: '#757575'
    }
  }),
  pagestitle: () => ({
    fontFamily: `${theme?.typography?.astFontFamily} !important`,
    lineHeight: '1.2 !important',
    fontSize: '2.5em',
    fontWeight: '100 !important',
    textAlign: 'center !important',
    margin: '20px 0px !important'
  }),
  dialogWrapper: () => ({
    // padding: theme.spacing(2),
    paddingTop: '0',
    position: 'absolute',
    top: theme.spacing(5),
    width: '100%',
    height: '650px'
  }),
  dialogTitle: () => ({
    paddingRight: '0px',
    textAlign: 'center',
    position: 'absolute',
    right: '33px',
    top: '-16px',
    zIndex: 999
  }),
  typoAlign: () => ({
    textAlign: 'center'
    // fontFamily: `${theme?.typography?.astFontFamily} !important`
  }),
  cardContentSignIn: () => ({
    display: 'flex',
    padding: '0px !important',
    [theme.breakpoints.up('lg')]: {
      display: 'block'
    }
  }),
  cardContent: () => ({
    padding: '32px 16px !important'
  }),
  selectforme: () => ({
    position: 'absolute',
    right: '20px',
    marginRight: '20px',
    fontSize: '1.3em',
    // color: '#551A8B'
  }),
  bestMatch: () => ({
    position: 'absolute',
    backgroundColor: '#006fcf',
    color: '#fff',
    textTransform: 'uppercase',
    padding: '5px 10px',
    zIndex: 10,
    fontSize: '0.9em',
    boxShadow: '3px 3px 1px gray'
  }),
  viewProfile: () => ({
    cursor: 'pointer !important',
    color: `${theme.typography.astProgressbarBackground}`,
    border: 'none !important',
    // fontSize: '1.3em !important',
    // display: 'inline !important',
    [theme.breakpoints.down('md')]: {
      display: 'none !important'
    }
  }),
  arrowKey: () => ({
    fontSize: '4rem !important',
    paddingRight: '0px !important'
  }),
  buySellCardImg: () => ({
    height: '90px'
  }),
  cardImg: () => ({
    [theme.breakpoints.up('lg')]: {
      height: '90px',
      display: 'inline-block'
    },
    height: '30px'
  }),
  thankYou: () => ({
    textAlign: 'center',
    padding: '0px !important'
  }),
  thankYouProfileImg: () => ({
    height: '150px',
    width: '150px',
    borderRadius: '50%',
    overflow: 'hidden',
    marginRight: '10px',
    backgroundColor: '#d3d3d3',
    border: '1px solid #dddcdc'
  }),
  profileImg: () => ({
    height: '120px',
    width: '120px',
    [theme.breakpoints.down('md')]: {
      width: '85px',
      height: '85px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '70px',
      height: '70px'
    },
    // borderRadius: '50%',
    overflow: 'hidden',
    // marginRight: '30px',
    backgroundColor: '#d3d3d3',
    border: '1px solid #dddcdc'
    // marginTop: '15px'
  }),
  cardImgMargin: () => ({
    margin: '20px'
  }),
  cardText: () => ({
    marginTop: '20px',
    // fontFamily: `${theme?.typography?.astFontFamily} !important`,
    fontWeight: 'bold',
    fontSize: '1.5em'
  }),
  labelMargin: () => ({
    margin: '15px 10px !important'
  }),
  progressHeight: () => ({
    height: '15px'
  }),
  buy: () => ({
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    '& .MuiGrid': {
      flexBasis: 'initial !important'
    }
  }),
  profileBuy: () => ({
    // margin: '10px',
    alignItems: 'flex-start !important'
  }),
  gridFix: () => ({
    justifyContent: 'center',
    '& >div': {
      flexBasis: 'initial !important'
    }
  }),
  relative: {
    position: 'relative'
  },
  mainWrapper: () => ({
    position: 'relative',
    width: '100%',
    overflow: 'hidden !important',
    height: 'auto',
    paddingLeft: '10px',
    display: 'inline-block',
    marginTop: '90%',
    marginLeft: '10%',
    [theme.breakpoints.up('md')]: {
      width: '70%',
      height: '200px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '50%',
      height: '100px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      height: '300px'
    }
  }),
  cashback: () => ({
    fontSize: '45px !important',
    fontWeight: 'bold !important',
    padding: '10px'
  }),
  haveQuestions: () => ({
    color: `${theme.typography.astProgressbarBackground}`,
    textTransform: 'none !important'
  }),
  customPlacement: () => ({
    justifyContent: 'end !important'
  }),
  prev: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& a': {
      borderBottom: '0px solid'
    }
  }),
  helperBar: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '10px',
    width: '100%'
  }),
  leadFormHelperBar: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    width: '100%'
  }),
  dialogBox: () => ({
    padding: '0px !important'
  }),
  dialogClose: () => ({
    display: 'flex',
    justifyContent: 'flex-end'
  }),
  dialogHeading: () => ({
    fontWeight: '400 !important',
    textAlign: 'center'
  }),
  dialogButton: () => ({
    // padding: '15px 60px !important'
  }),
  dialogContent: () => ({
    lineHeight: '1.5 !important',
    paddingTop: '10px',
    fontSize: '1em !important'
  }),
  alignButtons: () => ({
    textAlign: 'center'
  }),
  checkBoxMargin: () => ({
    margin: 0,
    '& span p': {
      marginBottom: '0px !important',
      marginTop: '5px'
    },
    '& .checkbox': {
      marginBottom: '7px !important'
    },
    [theme.breakpoints.only('xs')]: {
      margin: '7px 5px 0px 2px',
      fontSize: '0.9em',
      textAlign: 'left'
    },
    [theme.breakpoints.only('sm')]: {
      margin: '0px 5px 0px 2px',
      fontSize: '0.9em',
      textAlign: 'left'
    }
  }),
  backPage: () => ({
    display: 'flex',
    alignItems: 'center',
    color: '#000000 !important',
    textAlign: 'center',
    justifyContent: 'space-between'
  }),
  cardOnSelect: () => ({
    width: '250px',
    height: '200px',
    display: 'inline-block',
    textAlign: 'center',
    boxShadow: '0px 0px 9px 1px rgb(0 0 0 / 10%)',
    cursor: 'pointer',
    fontSize: '12px',
    backgroundColor: `${theme.typography.astProgressbarBackground || '#9A996E'} !important`,
    color: '#000',
    borderRadius: '5px',
    transition: 'all 200ms ease-in-out',
    boxSizing: 'content-box',
    position: 'relative',
    marginTop: '20px',
    '&:hover': {
      transform: ' scale(1.1)'
    }
  }),
  signIncard: () => ({
    width: '250px',
    height: '200px',
    display: 'inline-block',
    textAlign: 'center',
    boxShadow: '0px 0px 9px 1px rgb(0 0 0 / 10%)',
    cursor: 'pointer',
    fontSize: '12px',
    backgroundSolor: 'white',
    color: ' #000',
    borderRadius: '5px',
    transition: 'all 200ms ease-in-out',
    boxSizing: 'content-box',
    position: 'relative',
    marginTop: '20px',
    '&:hover': {
      transform: ' scale(1.1)'
    }
  }),
  signInGridCard: () => ({
    width: '70% !important',
    height: '60px !important',
    [theme.breakpoints.up('lg')]: {
      height: '200px !important',
      display: 'inline-block !important'
    },
    [theme.breakpoints.up('md')]: {
      width: '100% !important'
    },
    [theme.breakpoints.up('xs')]: {
      width: '100% !important',
      height: 'auto !important'
    },
    display: 'flex !important'
  }),
  cardListing: () => ({
    maxWidth: '170px',
    display: 'inline-block',
    height: '145px',
    borderRadius: '10px',
    margin: '15px'
  }),
  agentProfile: () => ({
    padding: '0px 15px !important'
  }),
  cardListingpara: () => ({
    marginBottom: '16px',
    display: 'inline-block',
    whiteSpace: 'pre-line'
  }),
  cardListingContent: () => ({
    paddingBottom: '10px'
  }),
  imageSpec: () => ({
    padding: '12px',
    display: 'inline-block',
    height: '55px',
    verticalAlign: 'middle'
  }),
  cardListingAwards: () => ({
    maxWidth: 'auto',
    display: 'inline-block',
    height: 'auto',
    borderRadius: '10px',
    margin: '15px'
  }),
  recentListing: () => ({
    width: '90%'
  }),
  richTextPara: () => ({
    // fontSize: `${theme?.typography?.astBodyFontSize} !important`,
    // lineHeight: '1.5',
    padding: '20px'
  }),
  nextBtn: () => ({
    position: 'absolute',
    bottom: '50px',
    color: '#fff',
    backgroundColor: '#024E43'
  }),
  cardsGrid: () => ({
    width: '70% !important',
    margin: '20px 0px'
  }),
  caption: () => ({
    paddingTop: '20px',
    fontFamily: `${theme?.typography?.astFontFamily} !important`
  }),
  disclaimer: () => ({
    position: 'fixed',
    bottom: '20px'
  }),
  disclaimerText: () => ({
    fontSize: '1.15em',
    fontWeight: '300',
    lineHeight: '1.5'
  }),
  reward: () => ({
    textAlign: 'center',
    padding: '20px 0px'
  }),
  yesNo: () => ({
    paddingTop: '40px'
  }),
  parentDiv: () => ({
    margin: 'auto'
  }),
  text: {
    padding: '6px 16px !important'
  },
  yesNoButton: {
    // minWidth: `${theme.spacing(14)}px!important`,
    margin: '0em !important'
  },
  removeMargin: () => ({
    margin: '0 !important'
  }),
  listSection: () => ({
    padding: '0 10px',
    cursor: 'pointer'
  }),
  listItemSection: () => ({
    backgroundColor: 'rgb(243, 243, 241) !important',
    padding: '20px !important',
    marginBottom: '2em'
  }),
  loadingIconColor: () => ({
    color: `${theme.typography.astProgressbarBackground} !important`
  }),
  loading: () => ({
    paddingTop: '40px'
  }),
  customAlign: () => ({
    textAlign: 'center',
    padding: theme.spacing(2),
    color: `${theme?.typography?.astBodyTextColor} !important`,
    boxShadow: 'none !important',
    fontSize: `${theme?.typography?.astBodyFontSize}em !important` || '1em'
  }),
  centerScreen: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  }),
  changeSelection: () => ({
    textDecoration: 'underline'
  }),
  selectionUndo: () => ({
    position: 'relative',
    top: '8px',
    cursor: 'pointer'
  }),
  selectionText: () => ({
    marginLeft: '5px',
    cursor: 'pointer'
  }),
  agentGrid: () => ({
    display: 'flex',
    margin: '1px',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minWidth: '99%'
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: '85%'
    }
  }),
  agentGridBoth: () => ({
    display: 'flex',
    margin: '10px 5px 6px !important',
    flexDirection: 'column',
    minWidth: '49%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '99%'
    },
    [theme.breakpoints.only('md')]: {
      minWidth: '70%',
      marginTop: '10px !important'
    }
  }),
  agentTitle: () => ({
    display: 'flex',
    justifyContent: 'space-between'
  }),
  textUnderline: () => ({
    textDecoration: 'underline',
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.9rem'
    },
    [theme.breakpoints.down('382')]: {
      fontSize: '0.7rem',
      marginTop: '7px'
    }
  }),
  agentNoteTitle: () => ({
    fontSize: '1.5rem',
    marginTop: '5px',
    padding: '0px',
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.1rem',
      marginTop: '8px'
    },
    [theme.breakpoints.down('387')]: {
      fontSize: '1.0rem',
      marginTop: '8px'
    }
  }),
  agentIcon: () => ({
    position: 'relative',
    top: '5px'
  }),
  hrLine: () => ({
    // height: '5px',
    background: `${theme.typography.astProgressbarBackground} !important`
  }),
  contentAgent: () => ({
    [theme.breakpoints.down('md')]: {
      width: '80% !important',
      margin: 'auto !important'
    }
  }),
  contentView: () => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '415px',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    }
  }),
  agentTextContent: () => ({
    flexDirection: 'column',
    display: 'flex',
    width: '100%'
  }),
  agentTextBox: () => ({
    width: '100%',
    minHeight: '380px'
  }),
  agentConfirmationRoot: () => ({
    paddingBottom: '15px',
    background: 'white',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  }),
  singleAgentCard: () => ({
    width: '33% !important',
    marginBottom: '20px !important'
  }),
  agentConfirmationDetail: () => ({
    fontSize: '0.9em !important'
  }),
  CardConfirmationroot: () => ({
    margin: '3px',
    width: '45%',
    height: 'fit-content',
    overflow: 'inherit',
    [theme.breakpoints.down('xs')]: {
      width: '100% !important'
    },
    boxShadow: '0px 0px 9px 1px rgb(0 0 0 / 10%)',
    cursor: 'pointer',
    fontSize: '12px',
    backgroundSolor: 'white',
    color: ' #000',
    borderRadius: '5px',
    transition: 'all 200ms ease-in-out',
    boxSizing: 'content-box',
    position: 'relative',
    marginTop: '20px',
    '&:hover': {
      transform: 'scale(1.1)'
    },
    '&:focus': {
      transform: 'scale(1.1)'
    }
  }),
  CardConfirmationmedia: () => ({
    height: 0,
    paddingTop: '94%', // 16:9
    margin: '10%'
  }),
  CardConfirmationcontent: () => ({
    padding: '5px'
  }),
  endClose: () => ({
    justifyContent: 'end !important'
  }),
  textLegal: () => ({
    padding: '6px 0px !important',
    color: 'blue !important',
    textDecoration: 'underline !important'
  }),
  hrDivider: () => ({
    backgroundColor: `${theme.typography.astProgressbarBackground} !important`,
    width: '96%',
    margin: '1em'
  }),
  hrDivider50: () => ({
    backgroundColor: `${theme.typography.astProgressbarBackground} !important`,
    width: '50%',
    margin: '3em auto',
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    }
  }),
  counter: () => ({
    fontSize: '45px',
    fontWeight: 'bold',
    padding: '10px'
  }),
  brandLogo: () => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '3em 0em'
  }),
  brandImageDiv: () => ({
    height: '50px',
    maxWidth: '100%',
    display: 'inline-block',
    margin: '0 3% 0 0'
  }),
  brandImage: () => ({
    [theme.breakpoints.down('sm')]: {
      height: '30px'
    }
  }),
  addNoteButton: () => ({
    margin: '1em',
    textTransform: 'none',
    marginBottom: '10px',
    paddingBottom: '10px'
  }),
  IconButton: () => ({
    backgroundColor: '#7d2ae8'
  }),
  badgeMargin: () => ({
    margin: '33px',
    fontSize: '0.6rem',
    transform: 'scale(1) translate(-15%, 67%) !important',
    backgroundColor: `${theme.typography.astProgressbarBackground} !important`,
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.4rem'
    }
  }),
  textcount: () => ({
    display: 'flex',
    justifyContent: 'end',
    margin: '0px !important'
  }),
  agentFinishButton: () => ({
    // padding: '10px 40px',
    // border: '1px solid purple',
    fontSize: '1.15em',
    background: 'white !important',
    color: `${theme?.buttonStyle?.buttonBackgroundColor || '#024E43'} !important`,
    border: `1px solid ${theme?.buttonStyle?.buttonBackgroundColor || '#024E43'} !important`,
    boxShadow: 'none'
  }),
  commonText: () => ({
    fontFamily: `${theme?.typography?.astFontFamily} !important`
  }),
  sronly: ()=> ({
    position: 'absolute',
    width: '1px',
    height: '1px',
    padding: '0',
    margin: '-1px',
    overflow: 'hidden',
    clip: 'rect(0,0,0,0)',
    border: '0'
  }),
  gridFooter: () => ({
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: `${theme?.typography?.astFontFamily} !important`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
      padding: '0 0 0 4%'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
      textAlign: 'left'
    }
  }),
  secondColumn: () => ({
    [theme.breakpoints.down('sm')]: {
      marginLeft: '20px'
    }
  }),
  badgeResponsive: () => ({
    [theme.breakpoints.down('sm')]: {
      left: '0px'
    },
    '& span': {
      left: '20px',
      [theme.breakpoints.down('sm')]: {
        height: 'auto'
      }
    }
  }),
  arrowKeyIcon: () => ({
    cursor: 'pointer !important',
    color: `${theme.typography.astProgressbarBackground}`,
    border: 'none !important',
    paddingRight: '0px !important',
    verticalAlign: 'middle'
  }),
  link: () => ({
    marginLeft: '1em !important',
    marginBottom: '1em !important'
  }),
  primaryBackgroundColor: () => ({
    backgroundColor: `${theme.typography.astProgressbarBackground}`,
    color: '#FFF'
  }),
  primaryBackgroundColorOutlined: () => ({
    color: `${theme.typography.astProgressbarBackground}`,
    border: `1px solid ${theme.typography.astProgressbarBackground}`,
    padding: '5px',
    width: 'auto',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    display: 'inline'
  }),
  // customMarginBottom: () => ({
  //   marginBottom: '1em'
  // }),
  thankYouBodyText: () => ({
    margin: '0px 20px 50px'
  }),
  thankYouHeading: () => ({
    paddingBottom: '0px !important',
    textAlign: 'center'
  }),
  progressBar: () => ({
    top: '100px',
    left: '0px',
    right: '0px',
    zIndex: '100',
    position: 'fixed',
    maxWidth: '100%'
  }),
  themeSecondaryColor: () => ({
    color: `${theme.typography.astProgressbarBackground} !important`
  }),
  skipLink: (data) => ({
    position: 'absolute',
    top: '-40px',
    left: '0',
    padding: '6px',
    backgroundColor: '#000000 !important',
    color: '#FFF !important',
    border: '2px solid transparent !important',
    fontSize: '1.4em !important',
    borderRadius: '0 !important',
    borderBottomRightRadius: '8px !important',
    '&:focus': {
      left: '0',
      top: '0',
      transition: 'top 1s ease-out',
      zIndex: '9999'
    }
  }),
  legalResidencePara: () => ({
    padding: '0% 3.5%'
  })
}));
